/* eslint-disable import/prefer-default-export, object-curly-newline */
import { payloadUpdateStatus } from 'interfaces/Association';
import {
  createFederationPayload,
  federationListQuery,
  completeFederationListResponse,
  getFederationByIdResponse,
  responseCreateFederation,
  updateFederationPayload,
  federationListResponse,
} from 'interfaces/Federation';
import { defaultResponse } from 'interfaces/defaultResponse';
import api from 'services';
import convertQueryParamsConditions, { paramsQueryString } from 'utils/object';

const path = '/federation';

export const getCompleteFederationsList = async (
  params?: paramsQueryString,
): Promise<completeFederationListResponse> => {
  const response = await api.get<completeFederationListResponse>(`${path}/completeFederation`, {
    params: params ? convertQueryParamsConditions(params) : undefined,
  });
  return response.data;
};

export const getFederationsList = async (params?: federationListQuery): Promise<federationListResponse> => {
  const response = await api.get<federationListResponse>(`${path}/list`, { params });
  return response.data;
};

export const postCreateFederation = async (payload: createFederationPayload): Promise<responseCreateFederation> => {
  const response = await api.post<responseCreateFederation>(`${path}/`, payload);
  return response.data;
};

export const putFederation = async (payload: updateFederationPayload): Promise<responseCreateFederation> => {
  const { idFederation, ...currentPayload } = payload;

  const response = await api.put<responseCreateFederation>(`${path}/${idFederation}`, currentPayload);
  return response.data;
};

export const getFederationById = async (id: string): Promise<getFederationByIdResponse> => {
  const response = await api.get<getFederationByIdResponse>(`${path}/${id}`);
  return response.data;
};
export const putChangeStatus = async (payload: payloadUpdateStatus): Promise<defaultResponse> => {
  const response = await api.put<defaultResponse>(`${path}/changeStatus`, payload);
  return response.data;
};

/* eslint-disable import/prefer-default-export */
import convertQueryParamsConditions, { paramsQueryString } from 'utils/object';

import api from 'services';
import { reasonListResponse } from 'interfaces/Reason';

export const getReasonList = async (params: paramsQueryString): Promise<reasonListResponse> => {
  const response = await api.get<reasonListResponse>('/reason', { params: convertQueryParamsConditions(params) });
  return response.data;
};

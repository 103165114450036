export interface paramsQueryString {
  conditions?: { [key: string]: string | null | boolean };
  fields?: string[];
  values?: { [key: string]: string | null };
  filterValues?: { [key: string]: string | null };
}

const convertQueryParamsConditionsAndFilterValues = (
  values: paramsQueryString,
  groupBy: string = 'conditions',
): { [key: string]: string } => {
  const queryParams: { [key: string]: string } = {};
  for (const key in values.values) {
    if (Object.prototype.hasOwnProperty.call(values.values, key)) {
      queryParams[`values[${key}]`] = `${values.values[key]}`;
    }
  }
  for (const key in values.conditions) {
    if (Object.prototype.hasOwnProperty.call(values.conditions, key)) {
      queryParams[`${groupBy}[${key}]`] = `${values.conditions[key]}`;
    }
  }

  for (const key in values.filterValues) {
    if (values.filterValues[key] !== null) {
      queryParams[`${groupBy}[${key}]`] = `${values.filterValues[key]}`;
    }
  }

  if (values.fields) {
    queryParams.fields = values.fields.join(',');
  }

  return queryParams;
};

export { convertQueryParamsConditionsAndFilterValues };

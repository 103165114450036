import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { HelmetProvider } from 'react-helmet-async';

import store from 'store/index';
import CustomAlert from 'components/CustomAlert';
import App from './App';

const persistor = persistStore(store);
const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

const MainComponents = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              Components={{ customAlert: CustomAlert }}
            >
              <App />
            </SnackbarProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

root.render(
  process.env.REACT_APP_ENVIRONMENT === 'local' ? (
    <React.StrictMode>
      <MainComponents />
    </React.StrictMode>
  ) : (
    <MainComponents />
  ),
);

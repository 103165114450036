/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */

import { passwordRecovery, payloadSignup, recovery, usersListInterface } from 'interfaces/Users';
import { defaultResponse } from 'interfaces/defaultResponse';
import api from 'services';
import { paramsQueryString, convertQueryParamsConditionsAndFilterValues } from 'utils/objects';

export const getUsersList = async (params: paramsQueryString): Promise<usersListInterface> => {
  const response = await api.get<usersListInterface>('/users/list', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const postNewUser = async (payload: payloadSignup): Promise<defaultResponse> => {
  const response = await api.post<defaultResponse>('/users/signup', payload);
  return response.data;
};

export const postSendEmailRecovery = async (payload: recovery) => {
  const response = await api.post('/sendRecovery', payload);
  return response.data;
};

export const postRecoverPassword = async (payload: passwordRecovery) => {
  const response = await api.put('/passwordReset', payload);
  return response.data;
};

export const putUser = async (params: paramsQueryString): Promise<defaultResponse> => {
  const response = await api.put<defaultResponse>(
    '/users/updateUser',
    {},
    { params: convertQueryParamsConditionsAndFilterValues(params) },
  );

  return response.data;
};

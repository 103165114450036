/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Avatar, Box, CssBaseline, Drawer, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { Routes, Route, useLocation } from 'react-router-dom';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { ChevronLeft, ChevronRight, Menu } from '@mui/icons-material';
import { Stack } from '@mui/system';
import FakePage from 'pages/FakePage';
import logo from 'assets/images/logo_colorida_escura.png';
import routes from 'routes';
import Footer from 'components/Footer';
import ErrorBoundary from 'components/ErrorBoundary';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { setCloseDrawer } from 'store/ducks/Settings';
import NavItem from './NavItem';
import MenuOptions from './MenuOptions';

const drawerWidth = 300;

const Main = styled('main', {
  shouldForwardProp: (prop) => {
    return prop !== 'open';
  },
})<{
  open?: boolean;
}>(({ theme, open }) => {
  return {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  };
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== 'open';
  },
})<AppBarProps>(({ theme, open }) => {
  return {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

const DrawerHeader = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0.5),

    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  };
});

const ResponsiveDrawer = () => {
  const theme = useTheme();
  const location = useLocation();
  // const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();

  const { closeDrawer } = useAppSelector((state) => {
    return state.settings;
  });

  const handleDrawerOpen = () => {
    dispatch(setCloseDrawer({ closeDrawer: true }));
  };

  const handleDrawerClose = () => {
    dispatch(setCloseDrawer({ closeDrawer: false }));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={closeDrawer}
        elevation={0}
        sx={{
          borderRadius: 0,
          height: '80px',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(3px)',
          boxShadow: 'rgba(34, 51, 84, 0.2) 0px 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px',
          color: (t) => {
            return t.palette.primary.light;
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(closeDrawer && { display: 'none' }), color: 'white' }}
          >
            <Menu color="primary" sx={{ marginTop: '13px' }} />
          </IconButton>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              fontFamily="Gabarito"
              sx={{ fontSize: '1.7rem', marginTop: '15px' }}
            >
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              Portal{' '}
              <span style={{ color: '#27CD8A', fontSize: '700', fontStyle: 'italic', fontFamily: 'Gabarito' }}>
                assist
              </span>
            </Typography>
            <MenuOptions />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRadius: '0px 15px 15px 0px',
            //  backgroundColor: '#004668',
            //  background: '#004668',
            // backgroundImage: 'linear-gradient(180deg,rgba(0, 0, 0, 0.2) 100%, rgba(255, 255, 255, 0.3) 0%)',
            boxShadow: '3px 8px 8px 4px rgba(0, 0, 0, 0.3)',
          },
        }}
        variant="persistent"
        anchor="left"
        open={closeDrawer}
      >
        <DrawerHeader>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Box padding={3}>
              <Avatar
                alt="Vileve"
                variant="square"
                src={logo}
                sx={{ width: '75%', height: '90%', marginLeft: '20px' }}
              />
            </Box>
            <Box paddingTop={3}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeft color="secondary" /> : <ChevronRight color="secondary" />}
              </IconButton>
            </Box>
          </Stack>
        </DrawerHeader>

        <NavItem />
      </Drawer>
      <Main open={closeDrawer} sx={{ backgroundColor: '#f2f5f9' }}>
        <DrawerHeader />
        <Box mb={2} />
        <Grid container direction="row" justifyContent="space-between" sx={{ height: '92vh' }}>
          <Grid item xs={12}>
            <ErrorBoundary key={location.pathname}>
              <Routes>
                {routes.map(({ isPrivate, path, component: Component }) => {
                  if (isPrivate) {
                    return <Route path={path} element={Component} key={path} />;
                  }
                  return false;
                })}
                <Route path="*" element={<FakePage />} />
              </Routes>
            </ErrorBoundary>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
              <Footer />
            </Box>
          </Grid>
        </Grid>
      </Main>
    </Box>
  );
};

export default ResponsiveDrawer;

/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { errorRedux } from 'interfaces/redux';
import { responseFullCustomer, responseModelCustomer } from 'interfaces/Customer';
import { listQueryParams } from 'interfaces/Proposal';
import { getCustomerList, getFullCustomerByID } from './service';

export const customerList = createAsyncThunk<responseModelCustomer, listQueryParams, { rejectValue: errorRedux }>(
  'customer/list',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCustomerList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const fullCustomerDataById = createAsyncThunk<responseFullCustomer, number, { rejectValue: errorRedux }>(
  'customer/:id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getFullCustomerByID(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IProposalReports extends errorRedux {
  customerList: responseModelCustomer;
}

const initialState: IProposalReports = {
  status: 'idle',
  message: '',
  type: '',
  customerList: {
    status: 'success',
    message: '',
    document: [],
  },
};

export const CustomerReducer = createSlice({
  name: 'CustomerStatus',
  initialState,
  reducers: {
    clearCustomerStatus: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(customerList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.customerList = action.payload;
      })
      .addCase(customerList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearCustomerStatus } = CustomerReducer.actions;
export default CustomerReducer.reducer;

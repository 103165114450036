/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import api from 'services';
import { responseFullCustomer, responseModelCustomer } from 'interfaces/Customer';
import { listQueryParams } from 'interfaces/Proposal';

export const getCustomerList = async (payload: listQueryParams): Promise<responseModelCustomer> => {
  const response = await api.get<responseModelCustomer>('/customer/list', { params: payload });
  return response.data;
};

export const getFullCustomerByID = async (id: number): Promise<responseFullCustomer> => {
  const response = await api.get<responseFullCustomer>(`/customer/${id}`);
  return response.data;
};

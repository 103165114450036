/* eslint-disable object-curly-newline,  import/prefer-default-export */
import {
  associationCityListResponse,
  associationListResponse,
  completeAssociationListResponse,
  createAssociationPayload,
  editCities,
  getAssociationByIdResponse,
  payloadUpdateStatus,
  responseCreateAssociation,
  updateAssociationPayload,
} from 'interfaces/Association';
import { defaultResponse } from 'interfaces/defaultResponse';
import _ from 'lodash';
import api from 'services';
import convertQueryParamsCondition, { paramsQueryString } from 'utils/object';

const path = '/association';

export const getCompleteAssociationsList = async (
  params?: paramsQueryString,
): Promise<completeAssociationListResponse> => {
  const response = await api.get<completeAssociationListResponse>(`${path}/completeAssociation`, {
    params: params ? convertQueryParamsCondition(params) : undefined,
  });

  return response.data;
};

export const getAssociationsList = async (params?: paramsQueryString): Promise<associationListResponse> => {
  const response = await api.get<associationListResponse>(`${path}/list`, {
    params: params ? convertQueryParamsCondition(params) : undefined,
  });

  return response.data;
};

// TODO: Change fo complete Association
export const getAssociationById = async (id: string): Promise<getAssociationByIdResponse> => {
  const response = await api.get<getAssociationByIdResponse>(`${path}/${id}`);
  return response.data;
};

export const postCreateAssociation = async (payload: createAssociationPayload): Promise<responseCreateAssociation> => {
  const response = await api.post<responseCreateAssociation>(`${path}/`, payload);
  return response.data;
};

export const putAssociation = async (payload: updateAssociationPayload): Promise<responseCreateAssociation> => {
  const { idAssociation, ...currentPayload } = payload;

  const response = await api.put<responseCreateAssociation>(`${path}/${idAssociation}`, currentPayload);
  return response.data;
};

export const getCitiesList = async (id: number): Promise<associationCityListResponse> => {
  const response = await api.get<associationCityListResponse>(`/association/getcities/${id}`);
  return response.data;
};

export const postAssociationCities = async (payload: editCities): Promise<defaultResponse> => {
  const response = await api.post<defaultResponse>(`/association/cities/${payload.id}`, _.pick(payload, ['citiesIds']));
  return response.data;
};

export const putChangeStatus = async (payload: payloadUpdateStatus): Promise<defaultResponse> => {
  const response = await api.put<defaultResponse>(`${path}/changeStatus`, payload);
  return response.data;
};

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { errorRedux } from 'interfaces/redux';
import {
  groupDescriptions,
  groupsListResponse,
  groupsResponse,
  paramsDeleteAssociation,
  payloadPostUserInGroup,
} from 'interfaces/Groups';
import { usersListInterface } from 'interfaces/Users';
import { defaultResponse } from 'interfaces/defaultResponse';
import { baseResponse } from 'interfaces/BaseResponse';
import { paramsQueryString } from 'utils/objects';
import {
  deleteUserFromGroups,
  getGoupsInfoByEmailUser,
  getGroupsAndPermission,
  getGroupsInfoById,
  getGroupsList,
  getUserByGroupsList,
  postUserInGroup,
} from './service';

export const groupsAndPermission = createAsyncThunk<groupsResponse, string, { rejectValue: errorRedux }>(
  '/groups/groupsandpermission',
  async (application, { rejectWithValue }) => {
    try {
      const data = await getGroupsAndPermission(application);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const groupList = createAsyncThunk<groupsListResponse, paramsQueryString, { rejectValue: errorRedux }>(
  '/groups',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getGroupsList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);
export const groupsByEmailUser = createAsyncThunk<groupsListResponse, { email: string }, { rejectValue: errorRedux }>(
  '/groups/groupsByEmailUser',
  async ({ email }, { rejectWithValue }) => {
    try {
      const data = await getGoupsInfoByEmailUser(email);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const userByGroupList = createAsyncThunk<usersListInterface, number, { rejectValue: errorRedux }>(
  '/groups/userByGroup',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getUserByGroupsList(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const groupsInfoById = createAsyncThunk<groupDescriptions, number, { rejectValue: errorRedux }>(
  '/groups/getGroupsInfoById',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getGroupsInfoById(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const addUsersInGroup = createAsyncThunk<baseResponse, payloadPostUserInGroup, { rejectValue: errorRedux }>(
  '/groups/usersInGroup',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postUserInGroup(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const removeUserFromGroup = createAsyncThunk<
  defaultResponse,
  paramsDeleteAssociation,
  { rejectValue: errorRedux }
>('/groups/deleteUserFromGroups', async (params, { rejectWithValue }) => {
  try {
    const data = await deleteUserFromGroups(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

interface IGroupsAndPermissionsReducer extends errorRedux {
  groupsAndPermission: groupsResponse;
  groupList: groupsListResponse;
  userByGroupList: usersListInterface;
  addUsersInGroup: defaultResponse;
  groupsInfoById: groupDescriptions;
  removeUserFromGroup: defaultResponse;
}

const initialState: IGroupsAndPermissionsReducer = {
  status: 'idle',
  type: '',
  message: '',
  groupsAndPermission: {
    message: '',
    status: '',
    document: [{ id: 0, id_grupo: 0, descricao: '' }],
    rowsAffected: [],
    data: [
      {
        id_login: 0,
        usuario: '',
        funcionalidade: '',
        tipo: '',
        valor_interno: '',
        visualizar: false,
        editar: false,
        excluir: false,
        inserir: false,
      },
    ],
  },
  groupList: {
    message: '',
    status: '',
    document: [{ id: 0, descricao: '' }],
    rowsAffected: [],
  },
  userByGroupList: {
    message: '',
    status: 'error',
    document: [
      {
        id: 0,
        nome: '',
        email: '',
        cpf: '',
        data_nascimento: new Date(),
        responsavel_cpf: null,
        parceiro_cpf_cnpj: '',
        celular: '',
        ramal: null,
        status: '',
        aplicacao: '',
        e_gestor: false,
        e_proprietario: false,
      },
    ],
  },
  removeUserFromGroup: {
    message: '',
    status: 'error',
  },
  addUsersInGroup: {
    message: '',
    status: 'error',
  },
  groupsInfoById: {
    message: '',
    status: '',
    document: [
      {
        descricao: '',
        status: '',
        id: 0,
        id_grupo: 0,
      },
    ],
    rowsAffected: [],
  },
};

export const groupAndPermissionsReducer = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    clearGroupAndPermissions: (state) => {
      state.status = 'idle';
      state.groupsAndPermission = { status: '', message: '', data: [], document: [], rowsAffected: [] };
    },
    clearGroupInfo: (state) => {
      state.status = 'idle';
      state.groupsInfoById = {
        status: '',
        message: '',
        document: [{ descricao: '', id: 0, status: '', id_grupo: 0 }],
        rowsAffected: [],
      };
    },
    clearGroupList: (state) => {
      state.status = 'idle';
      state.groupList = { status: '', message: '', document: [], rowsAffected: [] };
    },
    clearUserByGroupList: (state) => {
      state.status = 'idle';
      state.groupList = { status: '', message: '', document: [], rowsAffected: [] };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(groupsAndPermission.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(groupsAndPermission.fulfilled, (state, action) => {
        state.status = 'completed';
        state.groupsAndPermission = action.payload;
      })
      .addCase(groupsAndPermission.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(groupList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(groupList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.groupList = action.payload;
      })
      .addCase(groupList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(userByGroupList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userByGroupList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.userByGroupList = action.payload;
      })
      .addCase(userByGroupList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(removeUserFromGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeUserFromGroup.fulfilled, (state) => {
        state.status = 'completed';
      })
      .addCase(removeUserFromGroup.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(addUsersInGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addUsersInGroup.fulfilled, (state) => {
        state.status = 'completed';
      })
      .addCase(addUsersInGroup.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(groupsInfoById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(groupsInfoById.fulfilled, (state, action) => {
        state.status = 'completed';
        state.groupsInfoById = action.payload;
      })
      .addCase(groupsInfoById.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});

// prettier-ignore
// eslint-disable-next-line max-len
export const { clearGroupAndPermissions, clearGroupList, clearUserByGroupList, clearGroupInfo } = groupAndPermissionsReducer.actions;
export default groupAndPermissionsReducer.reducer;

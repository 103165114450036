/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { cobapAssociation, cobapFederation, deleteCobap } from 'interfaces/Cobap';
import { errorRedux } from 'interfaces/redux';
import {
  getCobapAssociation,
  getCobapFederation,
  cobapAssociationPost,
  cobapFederationPost,
  cobapAssociationPut,
  cobapFederationPut,
  postCobapAssociation,
  postCobapFederation,
  putCobapAssociation,
  putCobapFederation,
  deleteCobapAssociation,
  deleteCobapFederation,
} from './service';

export const cobapAssociationList = createAsyncThunk<cobapAssociation, void, { rejectValue: errorRedux }>(
  'cobap/association-list',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getCobapAssociation();
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const cobapNewAssociation = createAsyncThunk<
  cobapAssociation,
  cobapAssociationPost,
  { rejectValue: errorRedux }
>('cobap/create-association', async (payload, { rejectWithValue }) => {
  try {
    const data = await postCobapAssociation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const cobapEditAssociation = createAsyncThunk<
  cobapAssociation,
  cobapAssociationPut,
  { rejectValue: errorRedux }
>('cobap/edit-association', async (payload, { rejectWithValue }) => {
  try {
    const data = await putCobapAssociation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const cobapFederationList = createAsyncThunk<cobapFederation, void, { rejectValue: errorRedux }>(
  'cobap/federation-list',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getCobapFederation();
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const cobapNewFederation = createAsyncThunk<cobapFederation, cobapFederationPost, { rejectValue: errorRedux }>(
  'cobap/create-federation',
  async (payload, { rejectWithValue }) => {
    try {
      const data: cobapFederation = await postCobapFederation(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const cobapEditFederation = createAsyncThunk<cobapFederation, cobapFederationPut, { rejectValue: errorRedux }>(
  'cobap/edit-federation',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putCobapFederation(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const cobapDeleteAssociation = createAsyncThunk<deleteCobap, number, { rejectValue: errorRedux }>(
  'entities/entities/assosiation/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await deleteCobapAssociation(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const cobapDeleteFederation = createAsyncThunk<deleteCobap, number, { rejectValue: errorRedux }>(
  'entities/entities/federation/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await deleteCobapFederation(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface ICobap extends errorRedux {
  cobapAssociationList: cobapAssociation;
  cobapNewAssociation: cobapAssociation;
  cobapFederationList: cobapFederation;
  cobapNewFederation: cobapFederation;
  cobapDeleteAssociation: deleteCobap;
  cobapDeleteFederation: deleteCobap;
}

const initialState: ICobap = {
  status: 'idle',
  message: '',
  type: '',
  cobapAssociationList: {
    status: '',
    message: '',
    document: [],
  },
  cobapNewAssociation: {
    status: '',
    message: '',
    document: [],
  },
  cobapFederationList: {
    status: '',
    message: '',
    document: [],
  },
  cobapNewFederation: {
    status: '',
    message: '',
    document: [],
  },
  cobapDeleteAssociation: {
    status: '',
    message: '',
    rowsAffected: [],
  },
  cobapDeleteFederation: {
    status: '',
    message: '',
    rowsAffected: [],
  },
};

export const cobapReducer = createSlice({
  name: 'cobap',
  initialState,
  reducers: {
    clearStatusCobap: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cobapAssociationList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapAssociationList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
        state.cobapAssociationList = action.payload;
      })
      .addCase(cobapAssociationList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(cobapNewAssociation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapNewAssociation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.cobapNewAssociation = action.payload;
        state.type = action.type;
      })
      .addCase(cobapNewAssociation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(cobapEditAssociation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapEditAssociation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
      })
      .addCase(cobapEditAssociation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(cobapFederationList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapFederationList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.cobapFederationList = action.payload;
      })
      .addCase(cobapFederationList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(cobapNewFederation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapNewFederation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.cobapNewFederation = action.payload;
        state.type = action.type;
      })
      .addCase(cobapNewFederation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(cobapEditFederation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapEditFederation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
      })
      .addCase(cobapEditFederation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(cobapDeleteAssociation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapDeleteAssociation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
        state.cobapDeleteAssociation = action.payload;
      })
      .addCase(cobapDeleteAssociation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(cobapDeleteFederation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cobapDeleteFederation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
        state.cobapDeleteFederation = action.payload;
      })
      .addCase(cobapDeleteFederation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      });
  },
});
export const { clearStatusCobap } = cobapReducer.actions;
export default cobapReducer.reducer;

import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { companyHistoryList, companyList, payloadCompany } from 'interfaces/Company';
import { paramsQueryString } from 'utils/objects';
import { errorRedux } from 'interfaces/redux';
import { defaultResponse } from 'interfaces/defaultResponse';
import {
  putStatusCompanyToDelete,
  getCompanyHistory,
  getCompanyList,
  getCompanyThatHasProducts,
  getListOneCompany,
  postCreateCompany,
  putCompany,
} from './service';

export const listCompany = createAsyncThunk<companyList, paramsQueryString, { rejectValue: errorRedux }>(
  'company/listCompany',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCompanyList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const listHistoryCompany = createAsyncThunk<companyHistoryList, paramsQueryString, { rejectValue: errorRedux }>(
  'company/listHistoryCompany',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCompanyHistory(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const listOneCompanyByCnpj = createAsyncThunk<companyList, paramsQueryString, { rejectValue: errorRedux }>(
  'company/listOneCompany',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getListOneCompany(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const companyThatHasProducts = createAsyncThunk<companyList, paramsQueryString, { rejectValue: errorRedux }>(
  'company/companyThatHasProducts',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCompanyThatHasProducts(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const createCompany = createAsyncThunk<companyList, payloadCompany, { rejectValue: errorRedux }>(
  'company/newCompany',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postCreateCompany(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const updateCompany = createAsyncThunk<defaultResponse, paramsQueryString, { rejectValue: errorRedux }>(
  'company/updateCompany',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putCompany(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const deleteCompany = createAsyncThunk<defaultResponse, paramsQueryString, { rejectValue: errorRedux }>(
  'company/deleteCompany',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putStatusCompanyToDelete(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface ICompany extends errorRedux {
  companyThatHasProductsList: companyList;
  newCompany: companyList;
}

const initialState: ICompany = {
  status: 'idle',
  message: '',
  newCompany: {
    status: '',
    document: [],
    message: '',
  },
  companyThatHasProductsList: {
    status: '',
    document: [],
    message: '',
  },
};

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    clearCompany: (state) => {
      return {
        ...state,
        status: 'idle',
        message: '',
        type: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(companyThatHasProducts.pending, (state, action) => {
        return {
          ...state,
          status: 'loading',
          type: action.type,
        };
      })
      .addCase(companyThatHasProducts.fulfilled, (state, action) => {
        return {
          ...state,
          status: 'completed',
          message: action.payload.message,
          list: action.payload,
          type: action.type,
        };
      })
      .addCase(companyThatHasProducts.rejected, (state, action) => {
        return {
          ...state,
          status: 'failed',
          type: action.type,
          message: action.payload?.message || '',
        };
      })
      .addCase(createCompany.pending, (state, action) => {
        return {
          ...state,
          status: 'loading',
          type: action.type,
        };
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        return {
          ...state,
          status: 'completed',
          message: action.payload.message,
          newCompany: action.payload,
          type: action.type,
        };
      })
      .addCase(createCompany.rejected, (state, action) => {
        return {
          ...state,
          status: 'failed',
          message: action.payload?.message || '',
          type: action.type,
        };
      });
  },
});

export const { clearCompany } = company.actions;

export default company.reducer;

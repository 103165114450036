/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { responseCities } from 'interfaces/Parameterizations';
import { errorRedux } from 'interfaces/redux';
import { paramsQueryString } from 'utils/objects';
import { getCities } from './service';

export const fetchCities = createAsyncThunk<responseCities, paramsQueryString, { rejectValue: errorRedux }>(
  'parameterizations/cities',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCities(params);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IParameterizations extends errorRedux {
  cities: responseCities;
}

const initialState: IParameterizations = {
  status: 'idle',
  message: '',
  cities: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const ParametrizationReducer = createSlice({
  name: 'Parametrization',
  initialState,
  reducers: {
    clearParametrization: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.cities = action.payload;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
export const { clearParametrization } = ParametrizationReducer.actions;
export default ParametrizationReducer.reducer;

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { errorRedux } from 'interfaces/redux';
import { passwordRecovery, payloadSignup, recovery, usersListInterface } from 'interfaces/Users';
import { defaultResponse } from 'interfaces/defaultResponse';
import { paramsQueryString } from 'utils/objects';
import { getUsersList, postNewUser, postRecoverPassword, postSendEmailRecovery, putUser } from './service';

export const usersList = createAsyncThunk<usersListInterface, paramsQueryString, { rejectValue: errorRedux }>(
  'users/list',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getUsersList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const newUser = createAsyncThunk<defaultResponse, payloadSignup, { rejectValue: errorRedux }>(
  'users/signup',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postNewUser(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);
export const changeUser = createAsyncThunk<defaultResponse, paramsQueryString, { rejectValue: errorRedux }>(
  'users/updateUser',
  async (parmas, { rejectWithValue }) => {
    try {
      const data = await putUser(parmas);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const sendEmailRecovery = createAsyncThunk('user/sendRecovery', async (body: recovery, { rejectWithValue }) => {
  try {
    const data = await postSendEmailRecovery(body);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const recoveryPassword = createAsyncThunk(
  'user/passwordReset',
  async (body: passwordRecovery, { rejectWithValue }) => {
    try {
      const data = await postRecoverPassword(body);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IUser extends errorRedux {
  data: usersListInterface;
}

const initialState: IUser = {
  status: 'idle',
  message: '',
  type: '',
  data: {
    status: 'error',
    message: '',
    document: [],
  },
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(usersList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.data = action.payload;
      })
      .addCase(usersList.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
export const { clearUser } = user.actions;
export default user.reducer;

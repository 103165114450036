/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { FileData } from 'hooks/useFileDownloader';
import { baseResponse } from 'interfaces/BaseResponse';
import {
  externalProposal,
  filesProposal,
  filterValues,
  listQueryParams,
  proposal,
  proposalByIdentifierResponse,
  proposalChangesHistoryParams,
  proposalChangesHistoryResponse,
  proposalListCsv,
  putProposalCancellationPayload,
  resendContract,
  responseResendContract,
  statusHistoryProposal,
} from 'interfaces/Proposal';
import api from 'services';
import { convertQueryParamsConditionsAndFilterValues } from 'utils/objects';

export const getProposalList = async (payload: listQueryParams, bodyParams?: filterValues): Promise<proposal> => {
  const newParams = {
    ...payload,
    ...(bodyParams
      ? convertQueryParamsConditionsAndFilterValues({ filterValues: { ...bodyParams } }, 'filterValues')
      : undefined),
  };

  const response = await api.get<proposal>('/proposal/list', { params: newParams });
  return response.data;
};

export const getProposalListCsv = async (
  payload: proposalListCsv,
  bodyParams?: Omit<filterValues, 'startDate' | 'endDate'>,
): Promise<FileData> => {
  const newParams = {
    ...payload,
    ...(bodyParams
      ? convertQueryParamsConditionsAndFilterValues({ filterValues: { ...bodyParams } }, 'filterValues')
      : undefined),
  };

  const response = await api.get<Blob>('/reports/proposals', { params: newParams, responseType: 'blob' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, filename] = /filename="(.*)"$/.exec(response.headers['content-disposition']) ?? [];
  return { blob: response.data, fileName: filename };
};

export const getProposalByIdentifier = async (identifier: string): Promise<proposalByIdentifierResponse> => {
  const response = await api.get<proposalByIdentifierResponse>(`/proposal/byIdentifier/${identifier}`);
  return response.data;
};

export const getProposalHistory = async (identifier: string): Promise<statusHistoryProposal> => {
  const response = await api.get<statusHistoryProposal>(`/proposal/statusHistory/${identifier}`);
  return response.data;
};

export const getProposalFiles = async (id: number): Promise<filesProposal> => {
  const response = await api.get<filesProposal>(`/proposal/files/${id}`);
  return response.data;
};

export const postProposalResendContract = async (
  resendContractParams: resendContract,
): Promise<responseResendContract> => {
  const response = await api.post<responseResendContract>('/proposal/resendContract', resendContractParams);
  return response.data;
};

export const getProposalChangesHistory = async (
  data: proposalChangesHistoryParams,
): Promise<proposalChangesHistoryResponse> => {
  const response = await api.get<proposalChangesHistoryResponse>(`/proposal/changeHistory/${data.customer}`);
  return response.data;
};
export const putProposalCancellation = async (
  identifier: string,
  payload: putProposalCancellationPayload,
): Promise<baseResponse> => {
  const response = await api.put<baseResponse>(`proposal/cancellation/${identifier}`, payload);
  return response.data;
};

export const postExternalProposals = async (
  externalProposals: Array<externalProposal>,
): Promise<baseResponse> => {
  const response = await api.post<baseResponse>('proposal/create/bulk/', externalProposals);
  return response.data;
};

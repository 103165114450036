/* eslint-disable import/prefer-default-export */
import api from 'services';
import { cobapAssociation, cobapFederation, deleteCobap } from 'interfaces/Cobap';

export interface cobapAssociationPost extends Omit<cobapAssociation['document'][0], 'Key_Associacao' | 'id'> {}

export interface cobapFederationPost extends Omit<cobapFederation['document'][0], 'Key_Associacao' | 'id'> {}

export type cobapAssociationPut = Omit<
  cobapAssociationPost,
  'responsavel' | 'cpf' | 'nascimento' | 'email' | 'celular'
> & {
  id?: string;
  responsavel?: string;
  cpf?: string;
  nascimento?: Date;
  email?: string;
  celular?: string;
  // eslint-disable-next-line camelcase
  Key_Associacao?: string;
};

export type cobapFederationPut = Omit<
  cobapFederationPost,
  'responsavel' | 'cpf' | 'nascimento' | 'email' | 'celular'
> & {
  id?: string;
  responsavel?: string;
  cpf?: string;
  nascimento?: string;
  email?: string;
  celular?: string;
  // eslint-disable-next-line camelcase
  Key_Federacao?: string;
};

export const getCobapAssociation = async (): Promise<cobapAssociation> => {
  const response = await api.get<cobapAssociation>('/entities/entities/assosiation');
  return response.data;
};

export const postCobapAssociation = async (payload: cobapAssociationPost): Promise<cobapAssociation> => {
  const response = await api.post<cobapAssociation>('/entities/entities/assosiation', payload);
  return response.data;
};

export const putCobapAssociation = async (payload: cobapAssociationPut): Promise<cobapAssociation> => {
  // eslint-disable-next-line camelcase
  const { id, Key_Associacao, ...putPayload } = payload;
  const response = await api.put<cobapAssociation>(`/entities/entities/assosiation/${id}`, putPayload);
  return response.data;
};

export const getCobapFederation = async (): Promise<cobapFederation> => {
  const response = await api.get<cobapFederation>('/entities/entities/federation');
  return response.data;
};

export const postCobapFederation = async (payload: cobapFederationPost): Promise<cobapFederation> => {
  const response = await api.post<cobapFederation>('/entities/entities/federation', payload);
  return response.data;
};

export const putCobapFederation = async (payload: cobapFederationPut): Promise<cobapFederation> => {
  // eslint-disable-next-line camelcase
  const { id, Key_Federacao, ...putPayload } = payload;
  const response = await api.put<cobapFederation>(`/entities/entities/federation/${id}`, putPayload);
  return response.data;
};

export const deleteCobapAssociation = async (id: number): Promise<deleteCobap> => {
  const response = await api.delete<deleteCobap>(`/entities/entities/assosiation/${id}`);
  return response.data;
};

export const deleteCobapFederation = async (id: number): Promise<deleteCobap> => {
  const response = await api.delete<deleteCobap>(`/entities/entities/federation/${id}`);
  return response.data;
};

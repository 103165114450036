import React, { useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import dayjs from 'dayjs';
import theme from 'styles/theme/light';
import FakePage from 'pages/FakePage';
import routes from 'routes';
import { useAppSelector } from 'hooks/useAppStore';
import Layout from 'components/Layout';
import ErrorBoundary from 'components/ErrorBoundary';
import useSession from 'hooks/useSession';
import { SnackbarProvider } from 'notistack';
import CustomAlert from 'components/CustomAlert';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const session = useSession();
  const signedRedux = useAppSelector((state) => {
    return state.signer.signed;
  });

  useEffect(() => {
    if (window.location.pathname.startsWith('/maintenance')) {
      navigate('/maintenance');
    } else if (!signedRedux && !window.location.pathname.startsWith('/recoverypassword/')) {
      navigate('/signin');
    }
  }, [signedRedux, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      session.logoffIfExpired();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [session]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('pt-br')}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary key={location.pathname}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            Components={{ customAlert: CustomAlert }}
          >
            <Routes>
              {routes.map(({ isPrivate, component: Component, path }) => {
                if (!isPrivate) {
                  return <Route path={path} element={Component} key={path} />;
                }
                return false;
              })}
              <Route path="*" element={<FakePage />} />
              {signedRedux ? <Route path="/*" element={<Layout />} /> : <Route element={<Navigate to="/signin" />} />}
            </Routes>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;

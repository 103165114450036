/* eslint-disable import/prefer-default-export */
import api from 'services';
import {
  dateBoundedReportsParams,
  entitiesPendenciesReport,
  paramsPendingSignatures,
  pendingSignatures,
  pendingsClientParams,
  pendingsClientsResponse,
  reports,
  reportsCobapResponse,
  responseByTopTenEntitiesCobap,
} from 'interfaces/Reports';

const path = '/reports';

export const reportsCobapGet = async (payload: reports): Promise<reportsCobapResponse> => {
  const response = await api.get<reportsCobapResponse>(`${path}/general/generalreportcobap`, { params: payload });
  return response.data;
};

export const getCobapTopTenEntities = async (): Promise<responseByTopTenEntitiesCobap> => {
  const response = await api.get<responseByTopTenEntitiesCobap>(`${path}/dashboard/toptenentities`);
  return response.data;
};

export const getPedingSignatures = async (params: paramsPendingSignatures): Promise<pendingSignatures> => {
  const response = await api.get<pendingSignatures>(`${path}/general/pendingSignatures`, { params });
  return response.data;
};

export const getPedingSignaturesByEntities = async (
  params: dateBoundedReportsParams,
): Promise<entitiesPendenciesReport> => {
  const response = await api.get<entitiesPendenciesReport>(`${path}/general/pendencies`, { params });
  return response.data;
};

export const getClientReports = async (params: pendingsClientParams): Promise<pendingsClientsResponse> => {
  const response = await api.get<pendingsClientsResponse>(`${path}/general/pendingproposals`, { params });
  return response.data;
};

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { errorRedux } from 'interfaces/redux';
import {
  paramsPendingSignatures,
  reports,
  reportsCobapResponse,
  pendingSignatures as IPendingSignatures,
  dateBoundedReportsParams,
  entitiesPendenciesReport,
  pendingsClientParams,
  pendingsClientsResponse,
} from 'interfaces/Reports';
import { getClientReports, getPedingSignatures, getPedingSignaturesByEntities, reportsCobapGet } from './service';

export const reportCobap = createAsyncThunk('reports/cobap', async (payload: reports, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data);
  }
});

export const pendingSignatures = createAsyncThunk<
  IPendingSignatures,
  paramsPendingSignatures,
  { rejectValue: errorRedux }
>('reports/pendingSignatures', async (params: paramsPendingSignatures, { rejectWithValue }) => {
  try {
    const data = await getPedingSignatures(params);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data);
  }
});
export const getPendingSignaturesByEntities = createAsyncThunk<
  entitiesPendenciesReport,
  dateBoundedReportsParams,
  { rejectValue: errorRedux }
>('reports/pendingSignaturesByEntities', async (params: dateBoundedReportsParams, { rejectWithValue }) => {
  try {
    const data = await getPedingSignaturesByEntities(params);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data);
  }
});

export const getClientReportsCSV = createAsyncThunk<
  pendingsClientsResponse,
  pendingsClientParams,
  { rejectValue: errorRedux }
>('reports/pendingproposals', async (params, { rejectWithValue }) => {
  try {
    const data = await getClientReports(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

interface IReportsReducer extends errorRedux {
  reportCobap: reportsCobapResponse;
  reportClient: pendingsClientsResponse;
  pendingSignatures: IPendingSignatures;
  pendingSignaturesByEntities: entitiesPendenciesReport;
}

const initialState: IReportsReducer = {
  status: 'idle',
  message: '',
  type: '',
  reportCobap: {
    status: '',
    message: '',
    document: [],
  },
  pendingSignatures: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  reportClient: {
    status: 'error',
    message: '',
    document: [],
  },
  pendingSignaturesByEntities: {
    status: 'error',
    message: '',
    document: [
      {
        id: 0,
        sigla: '',
        cnpj: '',
        emails: '',
        telefones: '',
        responsavel: '',
        estado: '',
        tipo: '',
        nome: '',
        qt: 0,
      },
    ],
  },
};

export const reportsReducer = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearReports: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportCobap.pending, (state) => {
        state = { ...state, status: 'loading' };
      })
      .addCase(reportCobap.fulfilled, (state) => {
        state = { ...state, status: 'completed', reportCobap: state.reportCobap };
      })
      .addCase(reportCobap.rejected, (state) => {
        state = { ...state, status: 'failed', reportCobap: state.reportCobap };
      })
      .addCase(pendingSignatures.pending, (state) => {
        state = { ...state, status: 'loading' };
      })
      .addCase(pendingSignatures.fulfilled, (state, { payload }) => {
        state.status = 'completed';
        state.pendingSignatures = payload;
      })
      .addCase(pendingSignatures.rejected, (state) => {
        state = { ...state, status: 'failed', pendingSignatures: state.pendingSignatures };
      })
      .addCase(getPendingSignaturesByEntities.pending, (state) => {
        state = { ...state, status: 'loading' };
      })
      .addCase(getPendingSignaturesByEntities.fulfilled, (state, { payload }) => {
        state.status = 'completed';
        state.pendingSignaturesByEntities = payload;
      })
      .addCase(getPendingSignaturesByEntities.rejected, (state) => {
        state = { ...state, status: 'failed' };
      })
      .addCase(getClientReportsCSV.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(getClientReportsCSV.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.reportClient = action.payload;
        state.type = action.type;
      })
      .addCase(getClientReportsCSV.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      });
  },
});

export const { clearReports } = reportsReducer.actions;
export default reportsReducer.reducer;

/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import api from 'services';

import { baseResponse } from 'interfaces/BaseResponse';
import { payloadSelfValidation, responseFiles } from 'interfaces/Files';
import { convertQueryParamsConditionsAndFilterValues, paramsQueryString } from 'utils/objects';

const path = '/files';

export const postFiles = async (payload: FormData): Promise<baseResponse> => {
  const response = await api.post<baseResponse>(`${path}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const patchSelfValidation = async (payload: payloadSelfValidation): Promise<baseResponse> => {
  const response = await api.patch<baseResponse>(`${path}/validatePhoto`, payload);
  return response.data;
};

export const getFiles = async (params: paramsQueryString): Promise<responseFiles> => {
  const response = await api.get<responseFiles>(`${path}/list`, {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

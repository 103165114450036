/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { errorRedux } from 'interfaces/redux';
import { responseByTopTenEntitiesCobap, responseByGetAllReportsCobap, reportsQueryByCobap } from 'interfaces/Reports';
import { getCobapTopTenEntities, reportsCobapGet } from './service';

export const topTenEntitiesCobap = createAsyncThunk<responseByTopTenEntitiesCobap, void, { rejectValue: errorRedux }>(
  'reports/entities/cobap-top-ten-entities',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getCobapTopTenEntities();
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const reportsCobapGetAllProposalStatus = createAsyncThunk<
  responseByGetAllReportsCobap,
  reportsQueryByCobap,
  { rejectValue: errorRedux }
>('/reports/general/generalreportcobapProposalStatus', async (query, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const reportsCobapGetAllSubscriptionStatus = createAsyncThunk<
  responseByGetAllReportsCobap,
  reportsQueryByCobap,
  { rejectValue: errorRedux }
>('/reports/general/generalreportcobapSubscriptionStatus', async (query, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const reportsCobapGetAllProductionByPartner = createAsyncThunk<
  responseByGetAllReportsCobap,
  reportsQueryByCobap,
  { rejectValue: errorRedux }
>('/reports/general/generalreportcobapProductionByPartner', async (query, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const reportsCobapGetAllFormOfPayment = createAsyncThunk<
  responseByGetAllReportsCobap,
  reportsQueryByCobap,
  { rejectValue: errorRedux }
>('/reports/general/generalreportcobap', async (query, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const reportsCobapGetAllContract = createAsyncThunk<
  responseByGetAllReportsCobap,
  reportsQueryByCobap,
  { rejectValue: errorRedux }
>('/reports/general/generalreportcobapContract', async (query, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const reportsCobapGetAllRevenues = createAsyncThunk<
  responseByGetAllReportsCobap,
  reportsQueryByCobap,
  { rejectValue: errorRedux }
>('/reports/general/generalreportcobapRevenues', async (query, { rejectWithValue }) => {
  try {
    const data = await reportsCobapGet(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

interface IReportsReducer extends errorRedux {
  dataTopTenEntities?: responseByTopTenEntitiesCobap;
  dataGetAllReportsCobapProposalStatus?: responseByGetAllReportsCobap;
  dataGetAllReportsCobapSubscriptionStatus?: responseByGetAllReportsCobap;
  dataGetAllReportsCobapProductionByPartner?: responseByGetAllReportsCobap;
  dataGetAllReportsCobapFormOfPayment?: responseByGetAllReportsCobap;
  dataGetAllReportsCobapContract?: responseByGetAllReportsCobap;
  dataGetAllReportsRevenues?: responseByGetAllReportsCobap;
}

const initialState: IReportsReducer = {
  status: 'idle',
  message: '',
  type: '',
};

export const reportsCobapReducer = createSlice({
  name: 'cobapReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(topTenEntitiesCobap.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(topTenEntitiesCobap.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataTopTenEntities = action.payload;
        state.type = action.type;
      })
      .addCase(topTenEntitiesCobap.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(reportsCobapGetAllProposalStatus.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllProposalStatus.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataGetAllReportsCobapProposalStatus = action.payload;
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllProposalStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(reportsCobapGetAllSubscriptionStatus.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllSubscriptionStatus.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataGetAllReportsCobapSubscriptionStatus = action.payload;
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllSubscriptionStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(reportsCobapGetAllProductionByPartner.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllProductionByPartner.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataGetAllReportsCobapProductionByPartner = action.payload;
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllProductionByPartner.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(reportsCobapGetAllFormOfPayment.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllFormOfPayment.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataGetAllReportsCobapFormOfPayment = action.payload;
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllFormOfPayment.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(reportsCobapGetAllContract.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllContract.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataGetAllReportsCobapContract = action.payload;
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllContract.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(reportsCobapGetAllRevenues.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllRevenues.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.dataGetAllReportsRevenues = action.payload;
        state.type = action.type;
      })
      .addCase(reportsCobapGetAllRevenues.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});

export default reportsCobapReducer.reducer;

/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createFederationPayload,
  federationListQuery,
  completeFederationListResponse,
  getFederationByIdResponse,
  responseCreateFederation,
  updateFederationPayload,
  federationListResponse,
} from 'interfaces/Federation';
import { errorRedux } from 'interfaces/redux';
import { payloadUpdateStatus } from 'interfaces/Association';
import { defaultResponse } from 'interfaces/defaultResponse';
import { paramsQueryString } from 'utils/object';
import {
  getCompleteFederationsList,
  getFederationById,
  getFederationsList,
  postCreateFederation,
  putChangeStatus,
  putFederation,
} from './service';

export const completeFederationList = createAsyncThunk<
  completeFederationListResponse,
  paramsQueryString | undefined,
  { rejectValue: errorRedux }
>('federation/listFederations', async (query, { rejectWithValue }) => {
  try {
    const data = await getCompleteFederationsList(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const listFederations = createAsyncThunk<
  federationListResponse,
  federationListQuery | undefined,
  { rejectValue: errorRedux }
>('federation/listFederations', async (query, { rejectWithValue }) => {
  try {
    const data = await getFederationsList(query);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const postNewFederation = createAsyncThunk<
  responseCreateFederation,
  createFederationPayload,
  { rejectValue: errorRedux }
>('federation/newFederation', async (payload, { rejectWithValue }) => {
  try {
    const data = await postCreateFederation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const updateFederation = createAsyncThunk<
  responseCreateFederation,
  updateFederationPayload,
  { rejectValue: errorRedux }
>('federation/putFederation', async (payload, { rejectWithValue }) => {
  try {
    const data = await putFederation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const getFederationId = createAsyncThunk<getFederationByIdResponse, string, { rejectValue: errorRedux }>(
  'federation/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getFederationById(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const putFederationStatus = createAsyncThunk<defaultResponse, payloadUpdateStatus, { rejectValue: errorRedux }>(
  '/federation/changeStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putChangeStatus(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IFederation extends errorRedux {
  federationById: getFederationByIdResponse;
  newFederation: responseCreateFederation;
  updateFederation: responseCreateFederation;
}

const initialState: IFederation = {
  status: 'idle',
  message: '',
  newFederation: {
    status: 'error',
    document: [],
    message: '',
  },
  updateFederation: {
    status: 'error',
    document: [],
    message: '',
  },
  federationById: {
    status: 'error',
    document: [],
    message: '',
    data: {
      id: 0,
      nome: '',
      cnpj: '',
      sigla: '',
      emails: '',
      telefones: '',
      status: '',
      alterado_por: '',
      totalProposta: 0,
      alterado_em: new Date(),
      celular: '',
      criado_em: new Date(),
      endereco: {
        id: 0,
        cep: '',
        endereco: '',
        numero: null,
        bairro: '',
        cidade: '',
        estado: '',
        complemento: '',
      },
      responsavel: {
        id: 0,
        nome: '',
        cpf_responsavel: '',
        nascimento: new Date(),
        id_responsavel_entidade_assinatura: 0,
        email: '',
        celular: '',
      },
    },
  },
};
const federation = createSlice({
  name: 'federation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postNewFederation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(postNewFederation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.newFederation = action.payload;
        state.type = action.type;
      })
      .addCase(postNewFederation.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(updateFederation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(updateFederation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.updateFederation = action.payload;
        state.type = action.type;
      })
      .addCase(updateFederation.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(getFederationId.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(getFederationId.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.federationById = action.payload;
        state.type = action.type;
      })
      .addCase(getFederationId.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});

export default federation.reducer;

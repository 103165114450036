export interface paramsQueryString {
  conditions?: { [key: string]: string | null | boolean };
  fields?: string[];
  values?: { [key: string]: string | null };
}

const convertQueryParamsConditions = (values: paramsQueryString): { [key: string]: string } => {
  const queryParams: { [key: string]: string } = {};
  for (const key in values.values) {
    if (Object.prototype.hasOwnProperty.call(values.values, key)) {
      queryParams[`values[${key}]`] = `${values.values[key]}`;
    }
  }
  for (const key in values.conditions) {
    if (Object.prototype.hasOwnProperty.call(values.conditions, key)) {
      queryParams[`conditions[${key}]`] = `${values.conditions[key]}`;
    }
  }

  if (values.fields) {
    queryParams.fields = values.fields.join(',');
  }

  return queryParams;
};

export default convertQueryParamsConditions;

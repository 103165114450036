/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { companyHistoryList, companyList, payloadCompany } from 'interfaces/Company';
import api from 'services';
import { convertQueryParamsConditionsAndFilterValues, paramsQueryString } from 'utils/objects';
import { defaultResponse } from 'interfaces/defaultResponse';

export const getCompanyList = async (params: paramsQueryString): Promise<companyList> => {
  const response = await api.get<companyList>('/company', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const getListOneCompany = async (payload: paramsQueryString): Promise<companyList> => {
  const response = await api.get<companyList>('/company', {
    params: convertQueryParamsConditionsAndFilterValues(payload),
  });
  return response.data;
};

export const getCompanyHistory = async (params: paramsQueryString): Promise<companyHistoryList> => {
  const response = await api.get<companyHistoryList>('/company/history', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const getCompanyThatHasProducts = async (params: paramsQueryString): Promise<companyList> => {
  const response = await api.get<companyList>('/company/companyThatHasProducts', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const postCreateCompany = async (payload: payloadCompany): Promise<companyList> => {
  const response = await api.post<companyList>('/company', payload);
  return response.data;
};

export const putCompany = async (params: paramsQueryString): Promise<defaultResponse> => {
  const response = await api.put<defaultResponse>('/company', {}, {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const putStatusCompanyToDelete = async (params: paramsQueryString): Promise<defaultResponse> => {
  const response = await api.put<defaultResponse>(
    '/company/delete',
    {},
    { params: convertQueryParamsConditionsAndFilterValues(params) },
  );

  return response.data;
};

/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FileData } from 'hooks/useFileDownloader';
import { baseResponse } from 'interfaces/BaseResponse';
import {
  completeProposals,
  externalProposal,
  filesProposal,
  filterValues,
  listQueryParams,
  proposal,
  proposalByIdentifierResponse,
  proposalChangesHistoryParams,
  proposalChangesHistoryResponse,
  proposalListCsv as proposalListCsvExport,
  proposalSignatureStatusChange,
  proposalStatusChangeEvent,
  putProposalCancellationPayload,
  resendContract,
  responseResendContract,
  statusHistoryProposal,
} from 'interfaces/Proposal';
import { errorRedux } from 'interfaces/redux';
import {
  getProposalByIdentifier,
  getProposalChangesHistory,
  getProposalFiles,
  getProposalHistory,
  getProposalList,
  getProposalListCsv,
  postExternalProposals,
  postProposalResendContract,
  putProposalCancellation,
} from './service';

export const proposalList = createAsyncThunk<
  proposal,
  { payload: listQueryParams; bodyParams?: filterValues },
  { rejectValue: errorRedux }
>('proposal/list', async ({ payload, bodyParams }, { rejectWithValue }) => {
  try {
    const data = await getProposalList(payload, bodyParams);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const proposalListCsv = createAsyncThunk<
  FileData,
  { payload: proposalListCsvExport; bodyParams?: Omit<filterValues, 'startDate' | 'endDate'> },
  { rejectValue: errorRedux }
>('proposal/list', async ({ payload, bodyParams }, { rejectWithValue }) => {
  try {
    const data = await getProposalListCsv(payload, bodyParams);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const proposalByIdentifier = createAsyncThunk<proposalByIdentifierResponse, string, { rejectValue: errorRedux }>(
  'proposal/byIdentifier',
  async (searchAdvanced, { rejectWithValue }) => {
    try {
      const data = await getProposalByIdentifier(searchAdvanced);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const proposalHistory = createAsyncThunk<statusHistoryProposal, string, { rejectValue: errorRedux }>(
  '/proposal/statusHistory/identifier',
  async (identifier, { rejectWithValue }) => {
    try {
      const data = await getProposalHistory(identifier);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const proposalChangesHistory = createAsyncThunk<
  proposalChangesHistoryResponse,
  proposalChangesHistoryParams,
  { rejectValue: errorRedux }
>('/proposal/changesHistory/customer', async (payload, { rejectWithValue }) => {
  try {
    const data = await getProposalChangesHistory(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const proposalFiles = createAsyncThunk<filesProposal, number, { rejectValue: errorRedux }>(
  '/proposal/files/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getProposalFiles(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const resendContractType = createAsyncThunk<responseResendContract, resendContract, { rejectValue: errorRedux }>(
  '/proposal/resendContract',
  async (resendContractParams, { rejectWithValue }) => {
    try {
      const data = await postProposalResendContract(resendContractParams);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);
export const cancellateProposal = createAsyncThunk<
  baseResponse,
  { identifier: string; payload: putProposalCancellationPayload },
  { rejectValue: errorRedux }
>('proposal/cancellateProposal', async ({ identifier, payload }, { rejectWithValue }) => {
  try {
    const data = await putProposalCancellation(identifier, payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const sendExternalProposals = createAsyncThunk<
  baseResponse,
  Array<externalProposal>,
  { rejectValue: errorRedux }>('/proposal/create/bulk', async (externalProposals, { rejectWithValue }) => {
    try {
      const data = await postExternalProposals(externalProposals);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  });

interface IProposalReports extends errorRedux {
  proposalList: proposal;
  completeProposals: completeProposals;
  proposalHistory?: statusHistoryProposal['document'];
  proposalFiles?: filesProposal['document'];
  proposalChangesHistory?: proposalChangesHistoryResponse;
  proposalCancellation: baseResponse;
}

const initialState: IProposalReports = {
  status: 'idle',
  message: '',
  type: '',
  proposalCancellation: {
    status: '',
    message: '',
    rowsAffected: [],
  },
  proposalList: {
    status: '',
    message: '',
    document: [],
    data: { total: 0 },
  },
  completeProposals: {
    status: '',
    message: '',
    proposals: [],
  },
};

export const ProposalStatusReducer = createSlice({
  name: 'ProposalStatus',
  initialState,
  reducers: {
    updateProposalStatus: (state, action: PayloadAction<proposalStatusChangeEvent>) => {
      // prettier-ignore
      const { payload: { identifier, status } } = action;
      const listProposal = state.proposalList.document.find((_proposal) => {
        return _proposal.identificador === identifier;
      });
      const completeProposal = state.completeProposals.proposals.find((_proposal) => {
        return _proposal.proposal.identifier === identifier;
      });
      if (listProposal) {
        listProposal.status = status;
      }
      if (completeProposal) {
        completeProposal.proposal.status = status;
      }
    },
    updateSignersStatus: (state, action: PayloadAction<proposalSignatureStatusChange>) => {
      const {
        payload: { identificador, status_associacao, status_cliente, status_empresa, status_federacao },
      } = action;

      const currentProposal = state.completeProposals.proposals.find((prop) => {
        return prop.proposal.identifier === identificador;
      });

      if (currentProposal?.sign) {
        currentProposal.sign = {
          ...currentProposal.sign,
          associationStatus: status_associacao,
          enterpriseStatus: status_empresa,
          clientStatus: status_cliente,
          federationStatus: status_federacao,
        };
      }
    },
    clearProposalStatus: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(proposalList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.proposalList = action.payload;
      })
      .addCase(proposalList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(proposalByIdentifier.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalByIdentifier.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        const { proposals } = state.completeProposals;
        const fetchedProposal = action.payload.data;
        const index = proposals.findIndex((e) => {
          return e.proposal.identifier === fetchedProposal.proposal.identifier;
        });
        if (index === -1) {
          proposals.push(fetchedProposal);
          return;
        }
        proposals[index] = fetchedProposal;
      })
      .addCase(proposalByIdentifier.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(proposalHistory.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalHistory.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.proposalHistory = action.payload?.document;
        state.type = action.type;
      })
      .addCase(proposalHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(proposalFiles.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalFiles.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.proposalFiles = action.payload?.document;
        state.type = action.type;
      })
      .addCase(proposalFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(resendContractType.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(resendContractType.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
      })
      .addCase(resendContractType.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      })
      .addCase(proposalChangesHistory.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(proposalChangesHistory.fulfilled, (state, action) => {
        state.status = 'completed';
        state.proposalChangesHistory = action.payload;
        state.type = action.type;
      })
      .addCase(proposalChangesHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(cancellateProposal.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(cancellateProposal.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.proposalCancellation = action.payload;
        updateProposalStatus({ identifier: action.meta.arg.identifier, status: 'CANCELADA' });
      })
      .addCase(cancellateProposal.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
      });
  },
});
export const { clearProposalStatus, updateProposalStatus, updateSignersStatus } = ProposalStatusReducer.actions;
export default ProposalStatusReducer.reducer;

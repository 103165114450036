/* eslint-disable import/prefer-default-export */
/* eslint-disable object-curly-newline */
import api from 'services';

import { responseCities } from 'interfaces/Parameterizations';
import convertQueryParamsConditions, { paramsQueryString } from 'utils/object';

const path = 'parameterizations';

export const getCities = async (params: paramsQueryString): Promise<responseCities> => {
  const response = await api.get<responseCities>(`${path}/cities`, {
    params: convertQueryParamsConditions(params),
  });

  return response.data;
};

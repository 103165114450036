/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import api from 'services';
import {
  groupDescriptions,
  groupsListResponse,
  groupsResponse,
  paramsDeleteAssociation,
  payloadPostUserInGroup,
} from 'interfaces/Groups';
import { usersListInterface } from 'interfaces/Users';
import { defaultResponse } from 'interfaces/defaultResponse';
import { baseResponse } from 'interfaces/BaseResponse';
import { convertQueryParamsConditionsAndFilterValues, paramsQueryString } from 'utils/objects';

export const getGroupsAndPermission = async (application: string): Promise<groupsResponse> => {
  const response = await api.get<groupsResponse>('/groups/groupsandpermission', { params: { application } });
  return response.data;
};
export const getGroupsList = async (params: paramsQueryString): Promise<groupsListResponse> => {
  const response = await api.get<groupsListResponse>('/groups', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const postUserInGroup = async (payload: payloadPostUserInGroup): Promise<baseResponse> => {
  const response = await api.post<baseResponse>('/groups/usersInGroup', payload);
  return response.data;
};
export const getUserByGroupsList = async (id: number): Promise<usersListInterface> => {
  const response = await api.get<usersListInterface>(`/groups/userByGroup?id=${id}`);
  return response.data;
};
export const getGoupsInfoByEmailUser = async (email: string): Promise<groupsListResponse> => {
  const response = await api.get<groupsListResponse>(`/groups/groupsByEmailUser?email=${email}`);
  return response.data;
};
export const getGroupsInfoById = async (id: number): Promise<groupDescriptions> => {
  const response = await api.get<groupDescriptions>(`/groups/groupInfoById?id=${id}`);
  return response.data;
};
export const deleteUserFromGroups = async (params: paramsDeleteAssociation): Promise<defaultResponse> => {
  const response = await api.delete<defaultResponse>('/groups/deleteUserFromGroups', { data: params });
  return response.data;
};

/* eslint-disable react/react-in-jsx-scope */
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { Alert, AlertColor } from '@mui/material';

interface ReportCompleteProps extends CustomContentProps {
  severity: AlertColor;
}

const CustomAlert = forwardRef<HTMLDivElement, ReportCompleteProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const alertRef = useRef<HTMLDivElement>(null);

  const { message, severity } = props;

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.getElementsByTagName('button')[0].style.color = 'inherit';
    }
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent style={{ display: 'flex', justifyContent: 'flex-end' }} ref={ref}>
      <Alert
        ref={alertRef}
        style={{ borderRadius: '4px' }}
        variant="filled"
        severity={severity}
        onClose={handleDismiss}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});

CustomAlert.displayName = 'CustomAlert';

export default CustomAlert;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { salesReportResponse, salesReportQuery } from 'interfaces/Dashboard';
import { errorRedux } from 'interfaces/redux';
import { AxiosError } from 'axios';
import { getSalesReport } from './service';

interface IDashboard extends errorRedux {
  salesReport: salesReportResponse;
}

export const getSalesTableReport = createAsyncThunk<salesReportResponse, salesReportQuery, { rejectValue: errorRedux }>(
  'reports/getSalesTableReport',
  async (params: salesReportQuery, { rejectWithValue }) => {
    try {
      const data = await getSalesReport(params);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data);
    }
  },
);

const initialState: IDashboard = {
  status: 'idle',
  message: '',
  type: '',
  salesReport: {
    document: [
      {
        contagem: 0,
        data: '',
        nome: '',
        valor: 0,
      },
    ],
    status: 'error',
    message: '',
  },
};

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesTableReport.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(getSalesTableReport.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.salesReport = action.payload;
      })
      .addCase(getSalesTableReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});

export default dashboard.reducer;

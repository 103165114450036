/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorRedux } from 'interfaces/redux';

import { fullDataPartnerAndRelationShip, partnerList, payloadPartner } from 'interfaces/Partner';
import { paramsQueryString } from 'utils/objects';
import { defaultResponse } from 'interfaces/defaultResponse';
import {
  getPartnerAndRelationShip,
  getPartnerByEmailjUser,
  getPartnerList,
  postCreatePartner,
  putPartner,
} from './service';

export const listPartner = createAsyncThunk<partnerList, paramsQueryString, { rejectValue: errorRedux }>(
  'partner/listPartner',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getPartnerList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);
export const formPartner = createAsyncThunk<
  fullDataPartnerAndRelationShip,
  { cnpj: string },
  { rejectValue: errorRedux }
>('partner/fullData', async (payload, { rejectWithValue }) => {
  try {
    const data = await getPartnerAndRelationShip(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});
export const partnerByEmailUser = createAsyncThunk<partnerList, string, { rejectValue: errorRedux }>(
  'partner/listPartner',
  async (emailUser, { rejectWithValue }) => {
    try {
      const data = await getPartnerByEmailjUser(emailUser);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const createPartner = createAsyncThunk<partnerList, payloadPartner, { rejectValue: errorRedux }>(
  'partner/newPartner',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postCreatePartner(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const updatePartner = createAsyncThunk<defaultResponse, paramsQueryString, { rejectValue: errorRedux }>(
  'partner/updatePartner',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putPartner(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IPartner extends errorRedux {
  list: partnerList;
  fullData: fullDataPartnerAndRelationShip;
  newPartner: partnerList;
}

const initialState: IPartner = {
  status: 'idle',
  message: '',
  list: {
    status: '',
    document: [],
    message: '',
  },
  newPartner: {
    status: '',
    document: [],
    message: '',
  },
  fullData: {
    status: '',
    data: {
      partner: {
        cpfCnpj: '',
        corporateReason: '',
        fantasyName: '',
        application: '',
        comercialInstitution: 0,
        type: 'COLABORADOR',
        idAssociation: 0,
        idFederation: 0,
      },
      responsible: {
        birthdayResponsible: '',
        cpfResponsible: '',
        nameResponsible: '',
      },
      relationShipPartnerAssociation: {
        name: '',
        cnpj: '',
        acronym: '',
        status: '',
      },
      commercialInstitution: {
        cnpj: '',
        name: '',
      },
    },
    message: '',
  },
};
const partner = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    clearPartner: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listPartner.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(listPartner.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.list = action.payload;
        state.type = action.type;
      })
      .addCase(listPartner.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(createPartner.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.newPartner = action.payload;
        state.type = action.type;
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});

export const { clearPartner } = partner.actions;

export default partner.reducer;

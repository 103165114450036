/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  associationCityListResponse,
  associationListResponse,
  completeAssociationListResponse,
  createAssociationPayload,
  editCities,
  getAssociationByIdResponse,
  payloadUpdateStatus,
  responseCreateAssociation,
  updateAssociationPayload,
} from 'interfaces/Association';
import { errorRedux } from 'interfaces/redux';
import { defaultResponse } from 'interfaces/defaultResponse';
import { paramsQueryString } from 'utils/object';
import {
  getCompleteAssociationsList,
  getAssociationById,
  getCitiesList,
  postAssociationCities,
  postCreateAssociation,
  putAssociation,
  putChangeStatus,
  getAssociationsList,
} from './service';

export const completeAssociationList = createAsyncThunk<
  completeAssociationListResponse,
  paramsQueryString | undefined,
  { rejectValue: errorRedux }
>('association/completeAssociationsList', async (params, { rejectWithValue }) => {
  try {
    const data = await getCompleteAssociationsList(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const associationList = createAsyncThunk<
  associationListResponse,
  paramsQueryString | undefined,
  { rejectValue: errorRedux }
>('association/associationsList', async (params, { rejectWithValue }) => {
  try {
    const data = await getAssociationsList(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const associationById = createAsyncThunk<getAssociationByIdResponse, string, { rejectValue: errorRedux }>(
  'association/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getAssociationById(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const postNewAssociation = createAsyncThunk<
  responseCreateAssociation,
  createAssociationPayload,
  { rejectValue: errorRedux }
>('association/newAssociation', async (payload, { rejectWithValue }) => {
  try {
    const data = await postCreateAssociation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const updateAssociation = createAsyncThunk<
  responseCreateAssociation,
  updateAssociationPayload,
  { rejectValue: errorRedux }
>('association/putAssociation', async (payload, { rejectWithValue }) => {
  try {
    const data = await putAssociation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const associationCityList = createAsyncThunk<associationCityListResponse, number, { rejectValue: errorRedux }>(
  'association/getcities/id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getCitiesList(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const postCitiesForAssociation = createAsyncThunk<defaultResponse, editCities, { rejectValue: errorRedux }>(
  '/association/cities/id',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postAssociationCities(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);
export const putAssociationStatus = createAsyncThunk<defaultResponse, payloadUpdateStatus, { rejectValue: errorRedux }>(
  '/association/changeStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await putChangeStatus(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IAssociation extends errorRedux {
  associationById: getAssociationByIdResponse;
  newAssociation: responseCreateAssociation;
  updateAssociation: responseCreateAssociation;
  cityList: associationCityListResponse;
}

const initialState: IAssociation = {
  status: 'idle',
  message: '',
  newAssociation: {
    status: 'error',
    document: [],
    message: '',
  },
  updateAssociation: {
    status: 'error',
    document: [],
    message: '',
  },
  cityList: {
    status: 'error',
    document: [],
    data: [],
    message: '',
  },
  associationById: {
    status: 'error',
    document: [],
    message: '',
    data: {
      id: 0,
      codigo: '',
      nome: '',
      cnpj: '',
      sigla: '',
      data_filiacao: new Date(),
      emails: '',
      telefones: '',
      status: '',
      alterado_por: '',
      totalProposta: 0,
      alterado_em: new Date(),
      celular: '',
      criado_em: new Date(),
      endereco: {
        id: 0,
        cep: '',
        endereco: '',
        numero: null,
        bairro: '',
        cidade: '',
        estado: '',
        complemento: '',
      },
      responsavel: {
        id: 0,
        nome: '',
        cpf_responsavel: '',
        nascimento: new Date(),
        id_responsavel_entidade_assinatura: 0,
        email: '',
        celular: '',
      },
    },
  },
};
const association = createSlice({
  name: 'association',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postNewAssociation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(postNewAssociation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.newAssociation = action.payload;
        state.type = action.type;
      })
      .addCase(postNewAssociation.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(updateAssociation.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(updateAssociation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.updateAssociation = action.payload;
        state.type = action.type;
      })
      .addCase(updateAssociation.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(associationById.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(associationById.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.associationById = action.payload;
        state.type = action.type;
      })
      .addCase(associationById.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      })
      .addCase(associationCityList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(associationCityList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.type = action.type;
        state.cityList = action.payload;
      })
      .addCase(associationCityList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
        state.type = action.type;
      });
  },
});

export default association.reducer;

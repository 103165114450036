import { Paper, Container } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';

const Footer = () => {
  return (
    <Paper
      elevation={5}
      sx={{
        borderRadius: '0px',
        width: '100%',
        padding: '50px',
        marginTop: '20px',
        height: '120px',
      }}
    >
      <Container maxWidth="xl">
        <Stack>
          <Box sx={{ color: 'rgba(0, 0, 0, 0.5)' }} display="flex">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <Box sx={{ fontSize: '1rem', marginRight: '5px' }}>&#169;</Box> {new Date().getFullYear()} - Vileve
            Assistência
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
};

export default Footer;

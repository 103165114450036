/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { fullDataPartnerAndRelationShip, partnerList, payloadPartner } from 'interfaces/Partner';
import { defaultResponse } from 'interfaces/defaultResponse';
import api from 'services';
import { paramsQueryString, convertQueryParamsConditionsAndFilterValues } from 'utils/objects';

export const getPartnerList = async (params: paramsQueryString): Promise<partnerList> => {
  const response = await api.get<partnerList>('/partner', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};
export const getPartnerAndRelationShip = async (payload: { cnpj: string }): Promise<fullDataPartnerAndRelationShip> => {
  const response = await api.get<fullDataPartnerAndRelationShip>('/partner/fullData', { params: payload });
  return response.data;
};

export const postCreatePartner = async (payload: payloadPartner): Promise<partnerList> => {
  const response = await api.post<partnerList>('/partner/', payload);
  return response.data;
};

export const putPartner = async (params: paramsQueryString): Promise<defaultResponse> => {
  const response = await api.put<defaultResponse>(
    '/partner',
    { ...params.values },
    { params: convertQueryParamsConditionsAndFilterValues({ conditions: params.conditions, values: undefined }) },
  );

  return response.data;
};

export const getPartnerByEmailjUser = async (email: string): Promise<partnerList> => {
  const response = await api.get<partnerList>('/partner/user', { params: { emailUser: email } });
  return response.data;
};
